import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateAbout as onUpdateAbout,
  getAbout as onGetAbout,
} from "store/contacts/actions";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Base64 } from 'js-base64';

const EcommerceAddProduct = () => {
  document.title = "Hakkımızda Bilgileri | Yakın Mutfak Admin Paneli";

  const dispatch = useDispatch();

  const [getContactEdit, setContactEdit] = useState(null);
  const [getHtmlDataLong, setHtmlDataLong] = useState(null);


  function showToast(type) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Bilgileriniz Güncellendi"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }



  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mission: (getContactEdit && getContactEdit.Mission) || '',
      vission: (getContactEdit && getContactEdit.Vision) || '',
      shortdesc: (getContactEdit && getContactEdit.ShortDesc) || '',




    },
    validationSchema: Yup.object({
      shortdesc: Yup.string().required("Lütfen Kısa Açıklama Giriniz")
    }),
    onSubmit: (values) => {

      const updateOrder = {
        ID: users ? users[0].ID : 0,

        AboutDesc: getHtmlDataLong != null ? Base64.encode(getHtmlDataLong) : null,
       // AboutDescEng: getHtmlDataLongEng != null ? Base64.encode(getHtmlDataLongEng) : null,
       // AboutDescThird: getHtmlDataLongThird != null ? Base64.encode(getHtmlDataLongThird) : null,

        Mission: values.mission,
        Vision: values.vission,
        ShortDesc: values.shortdesc,



      };
      // update order
      dispatch(onUpdateAbout(updateOrder));
      //validation.resetForm();
      dispatch(onGetAbout());
      showToast(true)
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.about,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetAbout());

      
    }
  }, [dispatch, users]);

  useEffect(() => {
    console.log("nsdlf", users)
    if (users.length > 0) {
      setContactEdit(users[0]);

      if (users[0].AboutDesc != null) {
        setHtmlDataLong(Base64.decode(users[0].AboutDesc))
  
      }
  
    
    }
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Hakkımızda Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Hakkımızda Bilgileri</CardTitle>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>

                      <div className="mb-3">
                        <Label htmlFor="productname">Neden Biz kısa açıklama</Label>
                        <textarea
  id="shortdesc"
  name="shortdesc"
  className="form-control"
  placeholder="Başlık"
  onChange={validation.handleChange}
  onBlur={validation.handleBlur}
  value={validation.values.shortdesc || ""}
  style={{ border: validation.touched.shortdesc && validation.errors.shortdesc ? "1px solid red" : "1px solid black" }}

  rows={4} // isteğe bağlı olarak başlangıçta kaç satır gösterileceğini belirleyebilirsiniz
/>
{validation.touched.shortdesc && validation.errors.shortdesc ? (
  <FormFeedback type="invalid">{validation.errors.shortdesc}</FormFeedback>
) : null}

                      </div>


                      <div className="mb-3">
                        <Label htmlFor="productname">Misyon</Label>
                        <Input
                          id="mission"
                          name="mission"
                          type="text"
                          className="form-control"
                          placeholder="Misyon"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mission || ""}
                          invalid={
                            validation.touched.mission && validation.errors.mission ? true : false
                          }
                        />
                        {validation.touched.mission && validation.errors.mission ? (
                          <FormFeedback type="invalid">{validation.errors.mission}</FormFeedback>
                        ) : null}

                      </div>
                 

                       <div className="mb-3">
                        <Label htmlFor="productname">Vizyon</Label>
                        <Input
                          id="vission"
                          name="vission"
                          type="text"
                          className="form-control"
                          placeholder="Vizyon"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.vission || ""}
                          invalid={
                            validation.touched.vission && validation.errors.vission ? true : false
                          }
                        />
                        {validation.touched.vission && validation.errors.vission ? (
                          <FormFeedback type="invalid">{validation.errors.vission}</FormFeedback>
                        ) : null}

                      </div>
                  
              
                      <div className="mb-3">
                        <Label className="form-label">Uzun Açıklama</Label>

                        <Form method="post">
                          <CKEditor
                            editor={ClassicEditor}
                            data={getHtmlDataLong}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataLong(data)

                            }}
                          />
                        </Form>
                      </div>



                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
