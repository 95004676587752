import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";


import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";



import {

  getUsers as onGetUsers,
  getUserProfile as onGetUserProfile
} from "store/contacts/actions";
const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [mostUsedSubItemId, setMostUsedSubItemId] = useState(null);
  const [mostUsedSubItemTitle, setMostUsedSubItemTitle] = useState(null);
  const [mostUsedUserName, setMostUsedUserName] = useState(null);
  const [mostUsedUserId, setMostUsedUserId] = useState(null);

  const [mostUsedSubItems, setMostUsedSubItems] = useState([]);
  const [mostUsedUsers, setMostUsedUsers] = useState([]);

  const dispatch = useDispatch();


  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));

  const { userProfile } = useSelector(state => ({
    userProfile: state.contacts.userProfile,
  }));

  const reports = [
    { title: "En Çok Tamamlanan Eğitim", iconClass: "bx-archive-in", description: mostUsedSubItemTitle },   
    { title: "En Çok Eğitimi tamamlayan", iconClass: "bx-archive-in", description: mostUsedUserName },   

  ];

  useEffect(() => {
    if (users.length > 0 && userProfile.length > 0) {
      const countOccurrences = () => {
        const subItemCounts = {};
        const userCounts = {};

        userProfile.forEach((item) => {
          const subItemId = item.Education_SubItem_ID;
          subItemCounts[subItemId] = (subItemCounts[subItemId] || 0) + 1;
          const userId = item.Users_ID;
          userCounts[userId] = (userCounts[userId] || 0) + 1;
        });

        // En çok kullanılan Education_SubItem_ID'yi bul
        const mostUsedSubItemId = Object.keys(subItemCounts).reduce((a, b) =>
          subItemCounts[a] > subItemCounts[b] ? a : b
        );
        setMostUsedSubItemId(mostUsedSubItemId);

        // Bu ID'ye ait title'ı bul
        const mostUsedSubItem = userProfile.find(
          (item) => item.Education_SubItem_ID === parseInt(mostUsedSubItemId)
        );
        if (mostUsedSubItem) {
          setMostUsedSubItemTitle(
            mostUsedSubItem.Education_SubItem.Education_SubItem_Title
          );
        }

        // En çok kullanılan Users_ID'yi bul
        const mostUsedUserId = Object.keys(userCounts).reduce((a, b) =>
          userCounts[a] > userCounts[b] ? a : b
        );
        setMostUsedUserId(mostUsedUserId);

        // Bu ID'ye ait kullanıcı adını bul
        const mostUsedUser = userProfile.find(
          (user) => user.Users_ID === parseInt(mostUsedUserId)
        );
        if (mostUsedUser) {
          setMostUsedUserName(mostUsedUser.Users.Users_NameSurname);
        }
      };

      countOccurrences();
    }
  }, [users, userProfile]);


  useEffect(() => {
    if (users.length > 0 && userProfile.length > 0) {
      const countOccurrences = () => {
        const subItemCounts = {};
        const userCounts = {};

        userProfile.forEach((item) => {
          const subItemId = item.Education_SubItem_ID;
          subItemCounts[subItemId] = (subItemCounts[subItemId] || 0) + 1;
          const userId = item.Users_ID;
          userCounts[userId] = (userCounts[userId] || 0) + 1;
        });

        // En çok kullanılan 5 Education_SubItem_ID'yi bul
        const topSubItemIds = Object.keys(subItemCounts)
          .sort((a, b) => subItemCounts[b] - subItemCounts[a])
          .slice(0, 5);

        // Bu ID'lere ait title'ları bul
        const topSubItems = topSubItemIds.map((subItemId) => {
          const item = userProfile.find(
            (item) => item.Education_SubItem_ID === parseInt(subItemId)
          );
          return {
            id: subItemId,
            title: item.Education_SubItem.Education_SubItem_Title,
            count: subItemCounts[subItemId],
          };
        });

        setMostUsedSubItems(topSubItems);

        // En çok kullanılan 5 Users_ID'yi bul
        const topUserIds = Object.keys(userCounts)
          .sort((a, b) => userCounts[b] - userCounts[a])
          .slice(0, 5);

        // Bu ID'lere ait kullanıcı adlarını bul
        const topUsers = topUserIds.map((userId) => {
          const user = userProfile.find(
            (item) => item.Users_ID === parseInt(userId)
          );
          return {
            id: userId,
            name: user.Users.Users_NameSurname,
            count: userCounts[userId],
          };
        });

        setMostUsedUsers(topUsers);
      };

      countOccurrences();
    }
  }, [users, userProfile]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(onGetUsers());
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (userProfile.length === 0) {
      dispatch(onGetUserProfile());
    }
  }, [dispatch, userProfile]);

  //meta title
  document.title = "Anasayfa | Admin Paneli";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Yönetim")}
            breadcrumbItem={props.t("Anasayfa")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                       
                        </div>
                      </CardBody>
                    </Card>

                 
                    
                         

                  </Col>
                ))}
              </Row>

             <div>
      {/* En çok kullanılan 5 eğitim */}
      <h3>En Çok Tamamlanan 5 Eğitim</h3>
      <ul>
        {mostUsedSubItems.map((subItem) => (
          <li key={subItem.id}>
            {subItem.title} - Tamamlanma Sayısı: {subItem.count}
          </li>
        ))}
      </ul>

      {/* En çok kullanılan 5 kullanıcı */}
      <h3>En Çok Eğitim Tamamlayan 5 Kullanıcı</h3>
      <ul>
        {mostUsedUsers.map((user) => (
          <li key={user.id}>
            {user.name} - Tamamlanan Eğitim Sayısı: {user.count}
          </li>
        ))}
      </ul>
    </div>
    
            </Col>
          </Row>

        

         
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
